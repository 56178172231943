import { Injectable } from '@angular/core';
import { environment } from './common/constants/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root', // This makes the service available globally
})
export class SiteInfoService {
  constructor() {}

  private infoWebsite = new BehaviorSubject<any>(null);
  currentInfo = this.infoWebsite.asObservable();

  async fetchData() {
    try {
      const response = await fetch(
        `${environment.BASE_API_URL}sites/info-unauth/${window.location.hostname}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const { data } = await response.json();

      if (data?.infoWebsite){
        this.infoWebsite.next(data?.infoWebsite);
      }

      if (data?.infoWebsite?.title) {
        document.title = data.infoWebsite.title;
      }
      if (data?.infoWebsite?.urlFavicon) {
        const faviconLink:  any = document.getElementById('favIcon') ;
        if (faviconLink) {
          faviconLink.href = data.infoWebsite.urlFavicon;
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}
