<ng-container *ngIf="showModal">
  <div
    class="background"
    (click)="clickOutside()"
    [style.background]="backgroundColor"
    [ngStyle]="{ 'z-index': z_index }"
  ></div>
  <div
    class="modal-common"
    [style.background]="backgroundModal"
    [ngStyle]="{ 'z-index': z_index }"
    @modalAnimation
  >
    <span *ngIf="displayX" class="icon" (click)="closeModal()">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 6L6 18M6 6L18 18"
          stroke="#667085"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <ng-container *ngIf="templateRef">
      <ng-template [ngTemplateOutlet]="templateRef"> </ng-template>
    </ng-container>
  </div>
</ng-container>
