import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from './loading.service';
import { AnimationItem } from 'lottie-web';
import { LottieComponent, AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-loading-service',
  standalone: true,
  imports: [CommonModule, LottieComponent],
  templateUrl: './loading-service.component.html',
  styleUrls: ['./loading-service.component.scss'],
})
export class LoadingServiceComponent {
  textLoading = 'Đang tải dữ liệu';
  loading = false;
  lottieOptions = {
    path: 'assets/common/loading.json', // Đường dẫn đến file JSON
    loop: true, // Lặp liên tục
    autoplay: true,
  };

  lottieStyles = {
    width: '200px', // Điều chỉnh kích thước animation
    height: '200px',
  };
  constructor(private loadingService: LoadingService) {
    this.loadingService.$displayLoading.subscribe((value: any) => {
      this.loading = true;
      setTimeout(() => {
        this.loading = value;
      }, 800);
    });
  }
}
