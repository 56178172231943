import { APP_INITIALIZER, Component, OnInit } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LoadingServiceComponent } from './common/components/loading-service/loading-service.component';
import { MODAL_ID } from './common/components/modal-common/modal-id.const';
import { ModalCommonComponent } from './common/components/modal-common/modal-common.component';
import { ModalConfirmComponent } from './common/components/modal-confirm/modal-confirm.component';
import { ExcelService } from './modules/excel/excel.service';
import { ImageHandler } from 'ngx-quill-upload';
import Quill from 'quill';
Quill.register('modules/imageHandler', ImageHandler);
import { initFlowbite } from 'flowbite';
import { SignalrService } from './signalr.service';
import { SiteInfoService } from './site-info.service';
// import { SocketService } from './socket.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        RouterOutlet,
        RouterLink,
        LoadingServiceComponent,
        ModalCommonComponent,
        ModalConfirmComponent,
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ExcelService],
})
export class AppComponent implements OnInit {
    title = 'training-management-system';
    idConfirm = MODAL_ID.COMMON.CONFIRM;
    hubHelloMessage?: string;

    // private socketService: SocketService
    constructor(private signalrService: SignalrService, private siteInfoService: SiteInfoService) {}

    ngOnInit(): void {
        initFlowbite();
        const favicon = localStorage.getItem('favicon');
        if (favicon) {
            this.setFavicon(favicon);
        }

        this.siteInfoService.fetchData();
        this.signalrService.initiateSignalrConnection();
    }

    setFavicon(newFavicon: string) {
        let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
        favIcon.href = newFavicon;
    }

    // ngOnDestroy(): void {
    //     this.socketService.disconnect();
    // }
}
