import { Component } from '@angular/core';

@Component({
  templateUrl: '401.component.html',
})
export class UnauthorizedWarningComponent {
  constructor() {}

  goBack() {
    window.history.back();
  }
}
