<div class="flex flex-col gap-6 h-screen items-center justify-center">
  <div class="">
    <img
      class="w-full max-w-[350px] md:max-w-[600px] rounded-md"
      src="https://www.bluehost.com/blog/wp-content/smush-webp/2023/06/what-is-a-401-error-1536x864.png.webp"
    />
    <!-- <img
      class="hidden lg:block items-center"
      src="https://i.ibb.co/v30JLYr/Group-192-2.png"
    />
    <img
      class="hidden md:block lg:hidden"
      src="https://i.ibb.co/c1ggfn2/Group-193.png"
    />
    <img class="md:hidden" src="https://i.ibb.co/8gTVH2Y/Group-198.png" /> -->
  </div>
  <div class="text-center">
    <h1
      class="py-4 text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800 dark:text-white"
    >
      Xin lỗi, bạn không có quyền truy cập trang này!
    </h1>
    <!-- <p class="py-2 text-base text-gray-800 dark:text-white">
      Xin lỗi về điều đó! Vui lòng truy cập trang chủ của chúng tôi để đến nơi
      bạn cần đến.
    </p> -->

    <button
      (click)="goBack()"
      class="bg-blue-600 rounded-md mt-4 text-white hover:bg-blue-500 px-12 py-3"
    >
      Quay lại
    </button>
  </div>
</div>
