<div class="py-28 px-6">
  <div class="w-full flex justify-center">
    <img
      class="hidden lg:block items-center"
      src="https://i.ibb.co/v30JLYr/Group-192-2.png"
    />
    <img
      class="hidden md:block lg:hidden"
      src="https://i.ibb.co/c1ggfn2/Group-193.png"
    />
    <img class="md:hidden" src="https://i.ibb.co/8gTVH2Y/Group-198.png" />
  </div>
  <div class="w-full text-center">
    <h1
      class="py-4 mt-4 text-3xl lg:text-4xl font-extrabold text-gray-800 dark:text-white"
    >
      Trang bạn truy cập không tồn tại
    </h1>
    <p class="py-2 text-base text-gray-800 dark:text-white">
      Xin lỗi về điều đó! Vui lòng truy cập trang chủ của chúng tôi để đến nơi
      bạn cần đến.
    </p>

    <a
      href="/cms"
      class="w-[200px] mt-6 mx-auto flex justify-center my-4 rounded-md px-1 sm:px-16 py-5 bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50"
    >
      Về Trang chủ
    </a>
  </div>
</div>
