import { Position } from './Position';

export type User = {
  id: string;
  userName?: string;
  fullName: string;
  phoneNumber?: string;
  email?: string;
  address?: string;
  avatar?: string;
  password?: string;
  lastLogin?: Date;
  gender?: 1 | 2 | 3;
  managementUnit?: {
    managerLevel1: ManagementUnit;
    managerLevel2: ManagementUnit;
    managerLevel3: ManagementUnit;
  };
  status?: 0 | 1;
  dob?: string;
  position?: Position;
  isLockoutEnabled?: boolean;
  role?: UserRole;
  permissionsInfo?: [];
};

export enum UserRole {
  SuperAdmin = 'SUPERADMIN',
  Admin = 'ADMIN',
}

type ManagementUnit = {
  id: string;
  name: string;
  level1Id?: string;
  level1Name?: string;
  managerId?: string;
  managerName?: string;
};
